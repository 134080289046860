import request from "@/utils/request"

export function websiteInfo(data) {
    return request({
        method: 'post',
        url: '/web/website/info',
        data: data
    })
}

//联系人列表
export function contacts(data) {
    return request({
        method: 'post',
        url: '/web/liaison/list ',
        data: data
    })
}

//案列列表
export function instanceList(data) {
    return request({
        method: 'post',
        url: '/web/instance/list',
        data: data
    })
}

//案列分类列表
export function categoryList(data) {
    return request({
        method: 'post',
        url: '/web/instance/category/list',
        data: data
    })
}

//banner列表
export function bannerlist(data) {
    return request({
        method: 'post',
        url: '/web/banner/list',
        data: data
    })
}

//服务列表
export function serviceList(data) {
    return request({
        method: 'post',
        url: '/web/service/list',
        data: data
    })
}

//登录
export function login(data) {
    return request({
        method: 'post',
        url: '/web/user/login',
        data: data
    })
}

//注册

export function register(data) {
    return request({
        method: 'post',
        url: '/web/user/register',
        data: data
    })
}

//文章列表

export function articlesList(data) {
    return request({
        method: 'post',
        url: '/web/article/list',
        data: data
    })
}

//文章详情
export function articlesInfo(data) {
    return request({
        method: 'post',
        url: '/web/article/info',
        data: data
    })
}

//文章添加
export function articlesAdd(data) {
    return request({
        method: 'post',
        url: '/web/article/add',
        data: data
    })
}

//用户信息修改
export function useredit(data) {
    return request({
        method: 'post',
        url: '/web/user/edit',
        data: data
    })
}

//登陆用户的详细信息
export function userInfo(data) {
    return request({
        method: 'post',
        url: '/web/user/info',
        data: data
    })
}

//点赞
export function likeAdd(data) {
    return request({
        method: 'post',
        url: '/web/article/like/add',
        data: data
    })
}

//题库列表
export function problemList(data) {
    return request({
        method: 'post',
        url: '/web/problem/list',
        data: data
    })
}

//文章取消列表
export function likeDel(data) {
    return request({
        method: 'post',
        url: '/web/article/like/del',
        data: data
    })
}

//收藏问题
export function articleLike(data) {
    return request({
        method: 'post',
        url: '/web/problem/collect/add',
        data: data
    })
}

//问题取消收藏
export function articleDel(data) {
    return request({
        method: 'post',
        url: '/web/problem/collect/del',
        data: data
    })
}
//问题详情
export function problemInfo(data) {
    return request({
        method: 'post',
        url: '/web/problem/info',
        data: data
    })
}
//问题分类
export function problemBookList(data) {
    return request({
        method: 'post',
        url: '/web/problem/book/list',
        data: data
    })
}

//问题回答列表
export function answerList(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/list',
        data: data
    })
}

//评论回答
export function commentAdd(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/comment/add',
        data: data
    })
}

//评论列表
export function commentList(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/comment/list',
        data: data
    })
}
//回答问题
export function answerAdd(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/add',
        data: data
    })
}
//删除回答赞同反对
export function answerViewDel(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/view/del',
        data: data
    })
}
//回答赞同反对
export function answerViewAdd(data) {
    return request({
        method: 'post',
        url: '/web/problem/answer/view/add',
        data: data
    })
}
//发布问题
export function problemAdd(data) {
    return request({
        method: 'post',
        url: '/web/problem/add',
        data: data
    })
}
//分类的图标
export function BookAdd(data) {
    return request({
        method: 'post',
        url: '/web/problem/book/add',
        data: data
    })
}
