import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Info: {},
    },
    getters: {},
    mutations: {
        // Set_Info(state, value) {
        //     state.Info = value
        // },
    },
    actions: {},
    modules: {}
})
