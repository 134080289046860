<template>
  <div>
    <div style="width: 100%;border-top: 1px solid #eee;margin-top: 130px">
      <div class="bottom">
        <h2>{{ $store.state.Info.nameEn }}</h2>
        <div v-html="$store.state.Info.bottomDetails"></div>
        <el-link :href="$store.state.Info.icpUrl" target="_blank" :underline="false">
          <p class="p1-bottom">{{ $store.state.Info.icp }}</p>
        </el-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Bottom"
}
</script>

<style scoped>
.bottom {
  min-height: 194px;
  margin: 0 auto;
  width: 1200px;
  padding: 90px 15px 90px 15px;
  box-sizing: border-box;
}


.p1-bottom {
  text-align: center;
  color: #ddd;
  font-size: 14px;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .bottom {
    width: 100%;
  }
}
</style>
