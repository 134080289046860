const TokenKey = 'login_token'
const userInfoKey = 'user_info'

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}


export function setUser(user) {
    return localStorage.setItem(userInfoKey, JSON.stringify(user))
}

export function getUser() {
    return localStorage.getItem(userInfoKey)
}

export function removeUser() {
    return localStorage.removeItem(userInfoKey)
}
