<template>
  <div class="bg-header">
    <div class="header">
      <router-link to="/" class="router">
        <h2 style="flex-shrink: 0">{{ $store.state.Info.nameEn }}</h2>
      </router-link>
      <ul class="web-nav">
        <router-link to="/" class="router">
          <li class="li-item">
            首页
          </li>
        </router-link>
        <router-link to="/instance" class="router">
          <li class="li-item">
            案例
          </li>
        </router-link>
        <router-link to="/articleList" class="router">
          <li class="li-item">
            文章
          </li>
        </router-link>
        <router-link to="/question" class="router">
          <li class="li-item">
            题库
          </li>
        </router-link>

        <router-link to="/contactUs" class="router">
          <li class="li-item">
            联系我们
          </li>
        </router-link>
        <router-link v-show="!avatar" to="/login" class="router">
          <li class="li-item" style="padding-right: 0px">
            登录
          </li>
        </router-link>
        <!--        <router-link v-show="avatar" to="/login" class="router">-->
        <li v-show="avatar" style="padding-right: 0px;list-style: none;display: inline-block;" @click="gotopage">
          <el-avatar size="large" shape="circle" :src="headerImg"></el-avatar>
        </li>
        <!--        </router-link>-->

      </ul>
      <div class="mobile-nav">
        <i class="el-icon-s-unfold" style="font-size: 25px" @click="visible=!visible"></i>
      </div>
    </div>
    <div v-if="visible" class="mobile-nav-body">
      <div style="width: 90%; margin: 0 auto;">
        <router-link to="/" class="router">
          <div class="mobile-nav-box">
            首页
          </div>
        </router-link>
        <router-link to="/instance" class="router">
          <div class="mobile-nav-box">
            案例
          </div>
        </router-link>
        <!--        <router-link to="/About" class="router">-->
        <!--          <div class="mobile-nav-box">-->
        <!--            关于我们-->
        <!--          </div>-->
        <!--        </router-link>-->
        <router-link to="/articleList" class="router">
          <div class="mobile-nav-box">
            文章
          </div>
        </router-link>
        <router-link to="/question" class="router">
          <div class="mobile-nav-box">
            题库
          </div>
        </router-link>
        <router-link to="/contactUs" class="router">
          <div class="mobile-nav-box">
            联系我们
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {getToken, removeToken, removeUser} from "@/utils/auth";
import {userInfo} from "@/api/api"

export default {
  name: "homeNav",
  data() {
    return {
      isShow: false,
      visible: false,
      avatar: false,
      headerImg: ''
    }
  },
  methods: {
    getUserInfo() {
      userInfo({}).then(res => {
        if(res.code === 7){
          removeToken()
          removeUser()
        }
        this.headerImg = res.data.headerImg
      })
      // this.headerImg = info.headerImg
    },
    gotopage() {
      this.$router.push('./personal')
    }
  },
  mounted() {
    let token = getToken()
    if (token && token !== 'undefined') {
      this.avatar = true
      this.getUserInfo()
    } else {
      this.avatar = false

    }
  }
}
</script>

<style scoped>
.bg-header {
  height: 90px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  top: 0px;
  box-shadow: 2px 1px 10px 1px #ccc;
}

.header {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.web-nav {
  /*display: block;*/
  display: flex;
  align-items: center;
}

.mobile-nav {
  display: none;
  margin-right: 20px;
}

.mobile-nav-body {
  width: 100%;
  height: calc(100vh - 100px);
  position: fixed;
  top: 98px;
  left: 0;
  background: #fff;
  z-index: 100;
  display: none;
}


@media (max-width: 750px) {
  .web-nav {
    display: none;
  }

  .mobile-nav-body {
    display: block;
  }

  .bg-header {
    position: static;
  }

  .mobile-nav {
    display: block;
  }

  .bg-header {
    border-bottom: 1px solid #eee;
  }
}

.li-item {
  padding: 8px 18px;
  font-size: 14px;
  color: #111111;
  display: inline-block;
  list-style: none;
  font-family: Sans-serif;
}

.li-item:hover {
  color: #22a1db;
}

.router {
  text-decoration: none;
  color: #111111;
}

.mobile-nav-box {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #111111;
  border-bottom: 1px solid #eee;
  text-align: left;
}
</style>
