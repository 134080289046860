<template>
  <div class="bg-box">
    <HomeNav></HomeNav>
    <HomeBanner></HomeBanner>
    <Service></Service>
    <!--    <HomeMiddle></HomeMiddle>-->
    <BottomView></BottomView>
  </div>
</template>
<script>
// @ is an alias to /src

import HomeTop from "@/components/homeNav/homeNav";
import HomeBanner from "@/components/HomeBanner/HomeBanner"
import Service from "@/components/HomeBanner/service";
import BottomView from "@/components/BottomView/BottomView";

export default {
  name: 'HomeView',
  components: {
    HomeNav: HomeTop,
    HomeBanner: HomeBanner,
    Service,
    // HomeMiddle,
    BottomView,

  },
  metaInfo:{
    title:'临都科技-首页',
    meta:[
      {
        name:'关键字，web前端',
        content:'厺是啊多少度'
      }
    ]
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style>
body {
  padding: 0px;
  margin: 0px;
}

.bg-box {
  margin-top: 150px;
}

@media (max-width: 750px) {
  .bg-box {
    margin-top: 0px;
  }
}
</style>
