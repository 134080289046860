import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/instance',
        name: 'instance',
        meta: {
            title: "临都科技-服务"
        },
        component: () => import('@/views/instance/list.vue')
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/contactUs/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/articleRelease',
        name: 'articleRelease',
        component: () => import('@/views/article/release.vue')
    },
    {
        path: '/articleList',
        name: 'articleList',
        component: () => import('@/views/article/list.vue')
    },
    {
        path: '/articleDetails',
        name: 'articleDetails',
        component: () => import('@/views/article/details.vue')
    },
    {
        path: '/personal',
        name: 'personal',
        component: () => import('../views/personal/personal.vue')
    },
    {
        path: '/question',
        name: 'question',
        component: () => import('@/views/question/list.vue')
    },
    {
        path: '/answerList',
        name: 'answerList',
        component: () => import('@/views/question/answerList.vue')
    },
    {
        path: '/questionRelease',
        name: 'questionRelease',
        component: () => import('@/views/question/release.vue')
    },
    {
        path: '/answerRelease',
        name: 'answerRelease',
        component: () => import('@/views/question/answerRelease.vue')
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
