<template>
  <div class="bg-box">
    <p class="p1">We Are Professional
    </p>
    <div style="height: 77px;text-align: center;margin-bottom: 40px">
      <h2 style="margin-top: 0px;margin-bottom: 0px">Services </h2>
    </div>
    <div class="bg-content">
      <div class="content" v-for="(item,index) in list" :key="index">
        <el-image class="image" :src="item.img"></el-image>
        <h5 style="font-size: 20px;font-weight: bold">{{ item.name }}</h5>
        <p class="p2">{{ item.desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {serviceList} from "@/api/api"

export default {
  name: "homeContent",
  data() {
    return {
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      list: []
    }
  },
  mounted() {
    serviceList({
      page: 1,
      pageSize: 10
    }).then(res => {
      this.list = res.data.list
    })
  }

}
</script>

<style scoped>
.bg-box {
  width: 100%;
  margin-top: 55px;
}

.bg-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .bg-content {
    width: 100%;
  }
}

@media (max-width: 712px) {
  .bg-content {
    justify-content: center;
    margin-top: 20px;
  }
}


.p1 {
  margin-top: 20px;
  font-size: 16px;
  color: #22a1db;
  text-align: center;
}

.content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 20px 2px 20px;
  width: 270px;
  animation-duration: 1s;
  animation-name: fadeInLeft;
  margin-bottom: 40px;
}

@keyframes fadeInLeft {
  from {
    /* 将元素移到屏幕左侧*/
    opacity: 0;
    transform: scale(.6);
  }
  to {
    /* 元素进入屏幕 */
    opacity: 1;
    transform: scale(1);
  }
}


.content:hover {
  z-index: 2;
  box-shadow: 0 0 10px #888;
}


.image {
  width: 75px;
  height: 75px;
}

.p2 {
  font-size: 14px;
  color: #787878;
}
</style>
