<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
    </nav>
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app {
  cursor: url(/blue.cur), auto;
}


</style>
<script>
import {websiteInfo} from "@/api/api";

export default {

  mounted() {
    websiteInfo().then(res => {
      this.$store.state.Info = res.data
    })
  }
}
</script>
