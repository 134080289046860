import axios from "axios"
import {Message} from "element-ui";
import {getToken} from "@/utils/auth";

const request = axios.create({
    // baseURL: 'http://192.168.1.99:10888',
    baseURL: 'https://api.lindukj.com',
    withCredentials: false,//跨域请求时是否需要凭证
    timeout: 3 * 1000,//请求超时时间

})
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['x-token'] = getToken()
    return config
}, error => {
    return Promise.reject(error)
})
//请求拦截器
request.interceptors.request.use((config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
request.interceptors.response.use((response) => {
    if (response.status !== 200) {
        Message({message: response.statusText})
        return
    }
    let {code, msg} = response.data
    if (code != 0) {
        Message({message: msg})
        return response.data;
    }
    return response.data;

}, (error) => {
    return Promise.reject(error)
})
export default request;

