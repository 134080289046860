<template>
  <div>
    <div class="banner">
      <div class="bg-left">
        <div v-html="$store.state.Info.details"></div>
        <router-link to="/Contactus">
          <button class="btn">get started</button>
        </router-link>

      </div>
      <div class="img">
        <img style="width: 100%" :src="$store.state.Info.logo"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeBanner",
  data() {
    return {
      isBorder: false
    }
  },
  methods: {
    onClick() {

    }
  },
  mounted() {

  }
}

</script>

<style scoped>
.banner {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .banner {
    width: 100%;
  }
}


.bg-left {
  padding: 75px 15px;
  width: 400px;
  display: inline-block;
  text-align: left;
  animation-duration: 1s;
  animation-name: leftY;
}

.btn {
  margin-top: 20px;
  padding: 8px 20px;
  background-color: #22a1db;
  font-size: 15px;
  color: white;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
}

.btn:hover {
  border-radius: 50px;
}


.img {
  width: 540px;
  display: inline-block;
  padding: 0 15px;
  box-sizing: border-box;
  animation-duration: 1s;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  from {
    /* 将元素移到屏幕左侧*/
    opacity: 0;
    transform: scale(.6);
  }
  to {
    /* 元素进入屏幕 */
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes leftY {
  from {
    /* 将元素移到屏幕左侧*/
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    /* 元素进入屏幕 */
    opacity: 1;
    transform: none;
  }
}


</style>
